h1,h2, p, code, blockquote, ul, ol{
  margin: 0;
  padding:0;
}

p, code, blockquote, ul, ol{
  color: var(--text-color-default);
}

h1,h2{
  font-family: var(--heading-font-family);
}

p, code, blockquote, ul, ol, button{
  font-family: var(--body-text-font-family);
}

h1{
  font-style: normal;
  font-weight: 900;
  font-size: 3.2rem;
  line-height: 4.8rem;
  color: var(--neutal-900);

  @media (width > 1200px) {
    font-size: 4.4rem;
    line-height: 5.6rem;
  }
}

h2 {
  font-style: normal;
  font-weight: 700;
  color: var(--neutral-900);
  font-size: 1.8rem;
  line-height: 2.4rem;

  @media (width > 1200px) {
    font-size: 2.2rem;
    line-height: 2.9rem;
  }

}

p{
  font-size: 1.5rem;
  line-height: 1.8rem;


  @media (width > 1200px) {
    font-size: 1.7rem;
    line-height: 2.4rem;
  }

  &:not(:last-child){
    margin-block-end: 16px;
  }

  &.larger{
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.6rem;

    @media (width > 1200px) {
      font-size: 1.6rem;
      line-height: 2.8rem;
    }
  }

  &.subtitle{
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.8rem;
    letter-spacing: 0.01em;
    color: var(--text-color-accent);
  }
}

ol, ul{
  padding-inline-start: 28px;

  & li{
    font-size: 1.5rem;
    line-height: 1.8rem;


    @media (width > 1200px) {
      font-size: 1.7rem;
      line-height: 2.4rem;
    }
  }
}

ul{
  & li{
    list-style: none;
    position: relative;

    &::before{
      position: absolute;
      left: 0;
      content: '•';
      transform: translateX(-16px);
    }
  }
}

a {
  text-decoration: none;
  color: var(--brand-500);

  &:visited{
    text-decoration: none;
  }

  &:hover{
    text-decoration: underline;
  }

  &:active{
    opacity: 0.8;
  }
}
  
button,
.button{
  font-family: var(--body-text-font-family);
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 1.9rem;
  color: inherit;
  text-decoration: inherit;
} 
 