@font-face {
  font-family: Manrope;
  src: url("./fonts/Manrope-Regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url("./fonts/Manrope-Bold.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url("./fonts/Manrope-ExtraBold.otf");
  font-weight: 900;
  font-style: normal;
}