:root{
  /*
    Globals
  */

  /*
    Colors
  */
  --neutral-100: #fff;
  --neutral-200: #EEEEED;
  --neutral-300: #B9B9B6;
  --neutral-600: #72716E;
  --neutral-700: #31302F;
  --neutral-900: #000;
  --brand-100: #EBF1F3;
  --brand-200: #D9E4E8;
  --brand-500: #0085A3;
  --brand-600: #1F6473;
  --brand-900: #004E60;

  /*
  Gradients
  */
  --gradient-light: linear-gradient(180deg, #FFF 0%, #D3D0D5 100%);
  --gradient-dark: linear-gradient(180deg, #0085A3 0%, #004E60 100%);

  /*
    Shadows
  */
  --shadow-elevation-100: 0px 0px 0px 0.5px rgb(0 0 0 / 4%) inset;
  --shadow-elevation-200: 0px 2px 0px 0px rgb(255 255 255 / 25%) inset, 0px 0px 0px 1px rgb(0 0 0 / 12%) inset, 0px 3px 8px 0px rgb(0 0 0 / 12%), 0px 1px 1px 0px rgb(0 0 0 / 4%);

  /* 
    Specific colors
  */
  --text-color-default: var(--neutral-700);
  --text-color-accent: var(--brand-500);

  /*
    Fonts
  */
  --heading-font-family: "Manrope";
  --body-text-font-family: "Manrope";

  /*
    Generic
  */
  --page-background-color: var(--neutral-100);

  /* 
  HOME - left column
  */

  --button-text-color: var(--neutral-100);
  --button-icon-color: var(--neutral-100);
  --button-bg-color: var(--neutral-200);
  --button-gradient: var(--gradient-dark);
  --button-shadow: var(--shadow-elevation-200);
  --button-border-radius: 12px;
  --logo-height: 40px;

  /* 
  HOME - right column
  */

  --social-media-icon-color: var(--neutral-100);
  --social-media-bg-color: var(--brand-600);

  /* 
  DETAIL PAGE - right column
  */

  --social-media-icon-color-detailpage: var(--brand-500);
  --social-media-bg-color-detailpage: var(--brand-100);

  /* 
  in case of a solid color background:
  --product-shot-background: #ff1100;
  */
  
  /* in case of a gradient background:
   --product-shot-background: radial-gradient(100% 100% at 41.46% 100%, rgb(208 195 243 / 50%) 14.62%, rgb(92 85 165 / 50%) 63.14%, rgb(55 49 114 / 50%) 100%), #5C55A5;
  /*

  /* 
  in case of a image background
  --product-shot-background: #ff1100;
  --product-shot-bg-position: bottom center; 
  --product-shot-bg-size: 100% auto; 
  */;
  --product-shot-background: var(--gradient-dark);

  /* 
    Footer
  */ 
  --footer-text-color: var(--neutral-600);
}