.grid-wrapper{
  width: 100%;
  text-align: center;
}

.grid{
  width: calc(100% - 32px);
  height: 100%;
  max-width: 1170px;
  margin-inline-start: 16px;
  margin-inline-end: 16px;
  display: inline-grid;
  grid-gap: 30px;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}