.button{
  background: none;
  box-shadow: var(--button-shadow);
  border: none;
  background-color: var(--button-bg-color);
  height: 44px;
  border-radius: var(--button-border-radius);
  padding: 6px 20px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  flex-flow: row nowrap;
  color: var(--button-text-color);
  width: auto;
  

  &.gradient {
    background-image: var(--button-gradient);
  }

  &.reverse{
    padding: 6px 20px;
    flex-flow: row-reverse nowrap;
  }

  & .icon{
    height:16px;
    width:16px;
    flex: 0 0 16px;
    color: var(--button-icon-color);
  }

  &.circle{
    padding: 6px 20px 6px 6px;

    &.reverse{
      padding: 6px 6px 6px 20px;
    }

    & .icon{
      height:32px;
      width:32px;
      flex: 0 0 32px;
      background-color: var(--neutral-100);
      border-radius: 50%;
      display: grid;
      place-items: center;
    }
  }

  &:hover{
    text-decoration: none;
  }
}